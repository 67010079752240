import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Copy({ contentToParse, className, children }) {
  const classes = classNames('content__copy', className);

  if (contentToParse) {
    return <div className={classes}>{parse(contentToParse)}</div>;
  }
  return <div className={classes}>{children}</div>;
}

Copy.defaultProps = {
  contentToParse: false,
};

Copy.propTypes = {
  contentToParse: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  children: PropTypes.node,
};

export default Copy;
