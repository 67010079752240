import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Heading({
  level,
  style,
  preHeadingClasses,
  alignment,
  gapSize,
  className,
  preheading,
  children,
  headingRef,
  ...attributes
}) {
  const Tag = `h${level}`;

  const containerClasses = classNames(
    'content__header',
    alignment && `content__header--${alignment}`,
  );

  const classes = classNames('heading', className);

  const headerProps = {
    style,
    className: containerClasses,
    'data-gap': gapSize,
  };

  if (headingRef) headerProps.ref = headingRef;

  return (
    <header {...headerProps}>
      <Tag className={classes} {...attributes}>
        {children}
      </Tag>
      {preheading && <p className={`h4 heading__subheading ${preHeadingClasses}`}>{preheading}</p>}
    </header>
  );
}

Heading.defaultProps = {
  level: 2,
  gapSize: null,
  alignment: null,
  style: null,
  className: null,
  preheading: null,
  preHeadingClasses: '',
};

Heading.propTypes = {
  level: PropTypes.number.isRequired,
  gapSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  preheading: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * Classes for the preheading
   */
  preHeadingClasses: PropTypes.string,
};

export default Heading;
