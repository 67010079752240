import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import SchemaOrg from "./schema-org"

function Seo({ customData, isBlogPost, noindex }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              siteUrl
              image
              author
              organization {
                name
                url
                logo
              }
              social {
                twitter
                fbAppID
              }
            }
          }
        }
      `}
      render={({ site: { siteMetadata: defaultSeo } }) => {
        const seoMeta = customData || {}

        const title = seoMeta.title || defaultSeo.title
        const description = seoMeta.description || defaultSeo.description
        const image = `${defaultSeo.siteUrl}${
          seoMeta.image || defaultSeo.image
        }`
        const url = defaultSeo.siteUrl + seoMeta.url
        const datePublished = isBlogPost ? seoMeta.datePublished : false
        const dateModified = isBlogPost ? seoMeta.dateModified : false
        const postCategory = isBlogPost ? seoMeta.postCategory : false
        const author = seoMeta.author || defaultSeo.author
        const postImageAlt = seoMeta.postImageAlt || title
        const additionalSchema = seoMeta.additionalSchema || false

        return (
          <>
            <Helmet
              htmlAttributes={{
                lang: "en-US",
              }}
            >
              {/* <meta
             name="google-site-verification"
              content="9zDss4sAjNY9If_jb2KfNTgv3yymNhHp7QidLmOGU1s"
           /> */}
              <meta
                name="facebook-domain-verification"
                content="cd0pkyu7ppwvhw8ark0fed84otj7uz"
              />
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="image" content={image} />
              <link rel="canonical" href={url} />
              {noindex && <meta name="robots" content="noindex" />}
              {/* OpenGraph tags */}
              <meta property="og:locale" content="en_US" />
              <meta
                property="og:type"
                content={isBlogPost ? "article" : "website"}
              />
              {/* <link
              rel="alternate"
              hreflang="en"
              href="https://lunio.ai/"
            />
            <link rel="alternate" hreflang="fr" href="https://lunio.fr/" />
            <link
              rel="alternate"
              hreflang="x-default"
              href="https://lunio.ai/"
            /> */}
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:url" content={url} />
              <meta property="og:site_name" content="Lunio" />
              <meta property="og:image" content={image} key="pageImage" />
              ,
              <meta
                property="og:image:width"
                content="1200"
                key="pageImageWidth"
              />
              <meta
                property="og:image:height"
                content="650"
                key="pageImageHeight"
              />
              <meta
                property="og:image:alt"
                content={postImageAlt}
                key="imageAlt"
              />
              {isBlogPost && [
                <meta
                  property="og:updated_time"
                  content={dateModified}
                  key="updateTime"
                />,
                <meta
                  property="article:publisher"
                  content="https://facebook.com/luniohq"
                  key="articlePublisher"
                />,
                <meta
                  property="article:section"
                  content={postCategory}
                  key="articleCategory"
                />,
                <meta
                  property="article:published_time"
                  key="articlePublishTime"
                  content={datePublished}
                />,
                <meta
                  property="article:modified_time"
                  key="articleUpdateTime"
                  content={dateModified}
                />,
              ]}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:creator" content={author} />
              <meta name="twitter:site" content="@luniohq" />
              <meta name="twitter:image" content={image} />
              <meta name="twitter:image:alt" content={postImageAlt} />
            </Helmet>
            <SchemaOrg
              isBlogPost={isBlogPost}
              url={url}
              title={title}
              image={image}
              description={description}
              datePublished={datePublished}
              dateModified={dateModified}
              author={author}
              defaultTitle={defaultSeo.title}
              articleSection={postCategory}
              additionalSchema={additionalSchema}
            />
          </>
        )
      }}
    />
  )
}

Seo.propTypes = {
  isBlogPost: PropTypes.bool,
  postData: PropTypes.object,
}

Seo.defaultProps = {
  isBlogPost: false,
  postData: { childMarkdownRemark: {} },
}

export default Seo
