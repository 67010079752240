import React from "react"
import Helmet from "react-helmet"

export default React.memo(
  ({
    author,
    datePublished,
    dateModified,
    description,
    image,
    isBlogPost,
    additionalSchema,
    articleSection,
    title,
    url,
  }) => {
    const name = "Lunio"

    const baseSchema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name,
        url: "https://lunio.ai/",
        image: "https://lunio.ai/logos/logo.png",
      },
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        name: title,
        description,
        image: {
          "@type": "ImageObject",
          url: image,
          width: isBlogPost ? "1200" : "400",
          height: isBlogPost ? "650" : "82",
        },
        url,
      },
    ]

    let schema = [
      ...baseSchema,
      {
        "@context": "https://schema.org",
        "@type": "Corporation",
        name,
        alternateName: "Lunio Ltd",
        url,
        logo: image,
        address: {
          "@type": "PostalAddress",
          streetAddress: "4th Floor, Hyphen Building, 75 Mosley St",
          addressLocality: "Manchester",
          addressRegion: "M2 3HR",
          addressCountry: {
            "@type": "Country",
            name: "GB",
          },
        },
        sameAs: [
          "https://www.facebook.com/luniohq",
          "https://twitter.com/luniohq",
          "https://www.linkedin.com/company/lunio/",
        ],
      },
    ]

    if (isBlogPost) {
      schema = [
        ...schema,
        {
          "@context": "http://schema.org",
          "@type": "Article",
          author,
          datePublished,
          dateModified,
          mainEntityOfPage: "True",
          headline: title,
          articleSection,
          image: {
            "@type": "imageObject",
            url: image,
            height: "1200",
            width: "650",
          },
          publisher: {
            "@type": "Organization",
            name,
            logo: {
              "@type": "imageObject",
              url: "https://lunio.ai/logos/logo.png",
            },
          },
        },
      ]
    }

    if (additionalSchema) {
      schema = [...schema, additionalSchema]
    }

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    )
  },
)
