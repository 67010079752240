import React from "react"
import { graphql } from "gatsby"
import Heading from "../components/content/heading"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Rows from "../components/grid/rows"
import Columns from "../components/grid/columns"
import { Content } from "../components/grid"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import Copy from "../components/content/copy"
import SectionBackground from "@src/components/containers/section-background"

function PrivacyPage({ data, location }) {
  const { heading, seo, content } = data.strapiPageCookies

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent hero>
            <Rows gap="none" className="mb-md">
              <Columns count="1">
                <Content gap="md">
                  <Heading className="mt-xl" level={1}>
                    {heading.headingText}
                  </Heading>
                  <Copy>
                    <p>{heading.postHeadingText}</p>
                  </Copy>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="none">
          <Rows gap="xxl">
            <Columns count="1">
              <Content>
                <Copy contentToParse={content} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query CookiePageQuery {
    strapiPageCookies {
      heading {
        headingText
        postHeadingText
      }
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      content
    }
  }
`

export default PrivacyPage
